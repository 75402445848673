.testimonial-card {
  width: 45%;
  margin: 20px;
  padding: 30px;
  border-radius: 15px;
  background-color: #f9f9f9;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  text-align: center;
  position: relative;
}

.testimonial-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.3);
}

.testimonial-content {
  padding: 18px;
  color: #020202;
} 

.testimonial-text {
  font-size: 20px;
  font-style: italic;
  margin-bottom: 15px;
  text-align: left;
  line-height: 1.6;
  color: #080808;
}

.testimonial-author {
  font-size: 16px;
  font-weight: bold;
  color: #030303;
}

.testimonial-card:before {
  content: '"';
  font-size: 80px;
  color: rgba(14, 13, 13, 0.1);
  position: absolute;
  top: -10px;
  left: 10px;
}

.testimonial-card:after {
  content: '"';
  font-size: 80px;
  color: rgba(2, 2, 2, 0.1);
  position: absolute;
  bottom: -10px;
  right: 10px;
}

/* Container for the image to help with positioning */
.testimonial-author-image {
  position: absolute;
  top: -50px; /* Adjust based on image size */
  left: 50%;
  transform: translateX(-50%);
  width: 100px; /* Same as image width */
  height: 100px; /* Same as image height */
  border-radius: 50%;
  overflow: hidden;
  border: 3px solid #e4e1e1; /* Optional border */
  background-color: #fff; /* Background color in case image fails to load */
}

/* Media Query for medium screens (tablets, small desktops) */

@media (max-width: 1368px) {
  .testimonial-card {
    width: 45%; /* Adjust width to 60% for medium screens */
  }
}

@media (max-width: 1024px) {
  .testimonial-card {
    width: 45%; /* Adjust width to 60% for medium screens */
  }
}

/* Media Query for small screens (mobile devices) */
@media (max-width: 768px) {
  .testimonial-card {
    width: 70%; /* Increase the width to 80% for small screens */
  }
}

/* Media Query for very small screens (extra small mobile devices) */
@media (max-width: 480px) {
  .testimonial-card {
    width: 100%; /* Full width on very small screens */
    margin: 10px; /* Reduce margin to fit better on small screens */
  }
}
.testimonials-heading-text-div {
  text-align: center;
  padding: 30px 0;
}

.testimonials-heading-text {
  font-size: 40px;
  font-weight: bold;
  letter-spacing: 1.5px;
}

.testimonials-header-detail-text {
  font-size: 20px;
  max-width: 1000px;
  margin: 0 auto;
}

@media (max-width: 768px) {
  .testimonials-heading-text {
    font-size: 36px;
  }

  .testimonials-header-detail-text {
    font-size: 16px;
  }
}

.basic-testimonials {
  padding: 40px;
}

.testimonials-heading-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}

.testimonial-list {
  width: 100%;
  text-align: center;
  background-color: #ffffff;
  padding: 50px 0; 
} 

.testimonial-list-body {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 30px;
}

@media (max-width: 768px) {
  .testimonial-list-body {
    flex-direction: column;
    gap: 20px;
  }
}
